import React, { useEffect, useState } from "react"
import { Modal } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from "axios";
import { Link } from "react-router-dom";

const API_URL = process.env.REACT_APP_API_URL;

interface ModalProps {
    img_principal?: string;
    id_enlace?: number;
    url?: string;
}

const ModalPopup: React.FC = () => {
    const [show, setShow] = useState(false);
    const [popupImage, setPopupImage] = useState<ModalProps>();

    const handleClose = () => setShow(false);

    const getPopup = async () => {
        try {
            const response = await axios.get(`${API_URL}/enlaces/getPopup`)
            const item = response.data
            if (item.length > 0) {
                setShow(true);
                setPopupImage(item[0])
            }
        } catch (error) {
            console.log('Error: ', error)
        }
    }

    useEffect(() => {
        getPopup()
    }, []);

    return (
        <>
            <Modal
                show={show}
                onHide={handleClose}
                aria-labelledby="contained-modal-title-vcenter"
                centered
                contentClassName="customModal"
                size="lg"
            >
                <Modal.Header className="customModalHeader" closeButton>
                </Modal.Header>
                <Modal.Body>
                    {(popupImage?.url !== "") ?
                        <Link to={`${popupImage?.url}`}>
                            <img style={{ width: "100%" }} src={`https://api.upchiapas.edu.mx/images/${popupImage?.id_enlace}/${popupImage?.img_principal}`} />
                        </Link>
                        :
                        <img style={{ width: "100%" }} src={`https://api.upchiapas.edu.mx/images/${popupImage?.id_enlace}/${popupImage?.img_principal}`} />
                    }
                </Modal.Body>
            </Modal>
        </>
    )
}

export default ModalPopup;