import React from "react";
import { Modal, Button } from "react-bootstrap";
import { Perfil } from "../../Interfaces/Perfil";

interface ProfileModalProps {
  show: boolean;
  handleClose: () => void;
  perfil?: Perfil | null;
}

const ProfileModal: React.FC<ProfileModalProps> = ({ show, handleClose, perfil }) => {
  if (!perfil) return null;

  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Más información</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p><strong>Área de adscripción:</strong> {perfil.adscrito}</p>
        <p><strong>Líneas de investigación:</strong> {perfil.lineas}</p>
        <p><strong>Disponibilidad para dirigir tesis:</strong> {perfil.disponibilidad}</p>
        <p><strong>Áreas de interés:</strong> {perfil.interes}</p>
        <p><strong>Síntesis:</strong> {perfil.sintesis}</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Cerrar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ProfileModal;
