import React from 'react';
import { Row } from 'react-bootstrap';

interface InfoProps {
    url: string;
    titulo: string;
    cuerpo: string;
}

const InfoComponent: React.FC<InfoProps> = ({url, titulo, cuerpo}) => {
    return (
        <Row id={url}>
            <h2>{titulo}</h2>
            <div style={{ textAlign: 'justify' }} dangerouslySetInnerHTML={{ __html: cuerpo }}
            />
        </Row>
    )
}

export default InfoComponent;