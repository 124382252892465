import React, { useState } from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import 'bootstrap-icons/font/bootstrap-icons.css';
import { Link } from 'react-router-dom';

function MainMenu() {
    const [expanded, setExpanded] = useState(false);
    const [isDropdownOpen, setDropdownOpen] = useState({ universidad: false, nosotros: false, oferta: false, vinculacion: false, investigacion: false });

    const handleMouseOver = (menu: string) => {
        setDropdownOpen(prevState => ({ ...prevState, [menu]: true }));
    };

    const handleMouseOut = (menu: string) => {
        setDropdownOpen(prevState => ({ ...prevState, [menu]: false }));
    };

    const handleNavItemClick = () => {
        setExpanded(false);
    }

    return (
        <Navbar expand="lg" className="second-menu bg-body-tertiary" bg='dark' data-bs-theme='dark' sticky='top' collapseOnSelect
            expanded={expanded}
            onToggle={setExpanded}
        >
            <Container fluid>
                <Navbar.Toggle aria-controls="basic-navbar-nav_1" />
                <Navbar.Collapse id="basic-navbar-nav_1">
                    <Nav className="ms-auto">
                        <NavDropdown
                            title={<span className='second-menu-item'>NOSOTROS</span>}
                            id="collapsible-nav-nosotros"
                            show={isDropdownOpen.nosotros}
                            onMouseEnter={() => handleMouseOver('nosotros')}
                            onMouseLeave={() => handleMouseOut('nosotros')}
                            className="customNavDropDown"
                        >
                            <NavDropdown.Item onClick={handleNavItemClick} as={Link} to="/Bienvenida" className='customDropDownItem'>Bienvenida</NavDropdown.Item>
                            <NavDropdown.Item onClick={handleNavItemClick} as={Link} to="/Universidad" className='customDropDownItem'>Universidad</NavDropdown.Item>
                            <NavDropdown.Item onClick={handleNavItemClick} as={Link} to="/Administracion" className='customDropDownItem'>Administración</NavDropdown.Item>
                            {/*                             <NavDropdown.Item href="bienvenida">Bienvenida</NavDropdown.Item>
                            <NavDropdown.Item href="misionyvision">Misión y visión</NavDropdown.Item>
                            <NavDropdown.Item href="modeloeducativo">Modelo educativo</NavDropdown.Item>
                            <NavDropdown.Item href="estructuraorganica">Estructura orgánica</NavDropdown.Item>
                            <NavDropdown.Item href="programadesarrollo">PIDE</NavDropdown.Item>
                            <NavDropdown.Item href="directorio">Directorio</NavDropdown.Item>
                            <NavDropdown.Item href="informeanual">Informe anual</NavDropdown.Item>
                            <NavDropdown.Item href="politicaintegral">Política integral</NavDropdown.Item>
                            <NavDropdown.Item href="programaoperativoanual">Programa Operativo Anual</NavDropdown.Item>
                            <NavDropdown.Item href="acreditaciones">Acreditaciones</NavDropdown.Item>
                            <NavDropdown.Item href="certificaciones">Certificaciones</NavDropdown.Item>
                            <NavDropdown.Item href="pfce">PFCE</NavDropdown.Item> */}
                        </NavDropdown>
                        <NavDropdown
                            title={<span className='second-menu-item'>OFERTA EDUCATIVA</span>}
                            id="collapsible-nav-oferta"
                            show={isDropdownOpen.oferta}
                            onMouseEnter={() => handleMouseOver('oferta')}
                            onMouseLeave={() => handleMouseOut('oferta')}
                            className="customNavDropDown"
                        >
                            <NavDropdown.Item as={Link} to="/Oferta_Educativa">Ingenierías</NavDropdown.Item>
                            <NavDropdown.Item as={Link} to="/Maestrias">Maestrías</NavDropdown.Item>
                            <NavDropdown.Item href="https://di.upchiapas.edu.mx/">Doctorado</NavDropdown.Item>
                            <NavDropdown.Item onClick={handleNavItemClick} as={Link} to="/Admision" className='customDropDownItem'>Admisión</NavDropdown.Item>
                            { /* <NavDropdown.Item as={Link} to="/Multimedia">Multimedia</NavDropdown.Item> */ }
                        </NavDropdown>
                        <NavDropdown
                            title={<span className='second-menu-item'>VINCULACIÓN</span>}
                            id="collapsible-nav-vinculacion"
                            show={isDropdownOpen.vinculacion}
                            onMouseEnter={() => handleMouseOver('vinculacion')}
                            onMouseLeave={() => handleMouseOut('vinculacion')}
                            className="customNavDropDown"
                        >
                            <NavDropdown.Item as={Link} to="/Estancias_y_Estadias">Estancias</NavDropdown.Item>
                            <NavDropdown.Item as={Link} to="/Estancias_y_Estadias">Estadía</NavDropdown.Item>
                            <NavDropdown.Item as={Link} to="/Movilidad_Estudiantil">Movilidad Estudiantil</NavDropdown.Item>
                            <NavDropdown.Item href="https://2023.upchiapas.edu.mx/incubadora/">Incubadora de empresas</NavDropdown.Item>
                            <NavDropdown.Item as={Link} to="/Bolsa_de_Trabajo_Academico">Bolsa de trabajo</NavDropdown.Item>
                            <NavDropdown.Item href="https://2023.upchiapas.edu.mx/redconocer/">Red conocer</NavDropdown.Item>
                            <NavDropdown.Item as={Link} to="/Convenios">Convenios</NavDropdown.Item>
                        </NavDropdown>
                        <NavDropdown
                            title={<span className='second-menu-item'>INVESTIGACIÓN</span>}
                            id="collapsible-nav-investigacion"
                            show={isDropdownOpen.investigacion}
                            onMouseEnter={() => handleMouseOver('investigacion')}
                            onMouseLeave={() => handleMouseOut('investigacion')}
                            className="customNavDropDown"
                        >
                            <NavDropdown.Item as={Link} to="/Cuerpos_Academicos">Cuerpos académicos</NavDropdown.Item>
                            <NavDropdown.Item as={Link} to="/Proyectos_de_Investigacion">Proyectos de investigación</NavDropdown.Item>
                            <NavDropdown.Item as={Link} to="/Grupos_de_Investigacion">Grupos de investigación</NavDropdown.Item>
                            <NavDropdown.Item as={Link} to="/Investigadores">Investigadores</NavDropdown.Item>
                        </NavDropdown>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}

export default MainMenu;