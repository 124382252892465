import React, { useState } from "react";
import axios from "axios";
import FlipCard from "./FlipCard";
import ProfileModal from "./ProfileModal";
import { Container, Row, Col } from "react-bootstrap";
import { Perfil } from "../../Interfaces/Perfil";
import "bootstrap/dist/css/bootstrap.min.css";
import "animate.css";

const API_URL = process.env.REACT_APP_API_URL;

// Example Data
/* const perfiles: Perfil[] = [
    {
        id_enlace: "123",
        img_principal: "profile1.jpg",
        nombre: "John Doe",
        grado: "PhD",
        puesto: "Professor",
        email: "john@example.com",
        direccion: "Faculty of Engineering",
        extension: "1234",
        especialidad: "Computer Science",
        enlaces: "Research Lab",
        adscrito: "University Department",
        lineas: "AI, Machine Learning",
        disponibilidad: "Yes",
        interes: "Deep Learning, Robotics",
        sintesis: "Expert in AI and Data Science.",
    },
    // Add more profiles...
]; */

interface FlipCardProps {
    id_enlace: number;
    url?: string;
}

const FlipCardWrapper: React.FC<FlipCardProps> = ({ id_enlace, url }) => {
    const [selectedPerfil, setSelectedPerfil] = useState<Perfil | null>(null);
    const [imagesUrl, setImagesUrl] = useState<string>("");
    const [showModal, setShowModal] = useState(false);
    const [perfiles, setPerfiles] = useState<Perfil[] | null>(null);

    const handleShowModal = (perfil: Perfil) => {
        setSelectedPerfil(perfil);
        setShowModal(true);
    };

    const getChilds = async (enlaceId: any) => {
        let response = null;
        try {
            if(url === "Maestria_en_Energias_Renovables"){
                setImagesUrl("mer.upchiapas.edu.mx")
                response = await axios.post(`${API_URL}/enlaces/getProfilesMer`,
                    { id: enlaceId }
                )
            }
            if(url === "Maestria_en_Biotecnologia"){
                setImagesUrl("mb.upchiapas.edu.mx")
                response = await axios.post(`${API_URL}/enlaces/getProfilesMb`,
                    { id: enlaceId }
                )
            }
            if(url === "Doctorado_en_Ingenieria"){
                setImagesUrl("di.upchiapas.edu.mx")
                response = await axios.post(`${API_URL}/enlaces/getProfilesDi`,
                    { id: enlaceId }
                )
            }
            const items = response?.data
            setPerfiles(items)
        } catch (error) {
            console.log('Error: ', error)
        }
    }

    React.useEffect(() => {
        getChilds(id_enlace)
    }, [id_enlace]);

    return (
        <>
            <Row className="mt-4">
                {perfiles?.map((perfil) => (
                    <Col key={perfil.id_enlace} md={4} className="d-flex justify-content-center">
                        <FlipCard imagesUrl={imagesUrl} perfil={perfil} onShowModal={handleShowModal} />
                    </Col>
                ))}
            </Row>
            <ProfileModal show={showModal} handleClose={() => setShowModal(false)} perfil={selectedPerfil} />
        </>
    );
};

export default FlipCardWrapper;
