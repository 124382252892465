import React, { useState } from "react";
import { Card, Button } from "react-bootstrap";
import { Perfil } from "../../Interfaces/Perfil";
import "animate.css";

interface FlipCardProps {
  perfil: Perfil;
  imagesUrl: string;
  onShowModal: (perfil: Perfil) => void;
}

const FlipCard: React.FC<FlipCardProps> = ({ perfil, onShowModal, imagesUrl }) => {
  const [isFlipped, setIsFlipped] = useState(false);

  return (
    <div
      onMouseEnter={() => setIsFlipped(true)}
      onMouseLeave={() => setIsFlipped(false)}
      style={{
        width: "300px",
        height: "450px",
        perspective: "1000px",
        marginBottom: "10px",
        fontSize: "medium",
      }}
    >
      <div
        className="flip-card-inner card"
        style={{
          position: "relative",
          width: "100%",
          height: "100%",
          textAlign: "center",
          transition: "transform 0.6s",
          transformStyle: "preserve-3d",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
          transform: isFlipped ? "rotateY(180deg)" : "rotateY(0deg)",
        }}
      >
        {/* Front Side */}
        <Card.Body
          className="flip-card-front"
          style={{
            position: "absolute",
            width: "100%",
            height: "100%",
            backfaceVisibility: "hidden",
            backgroundColor: "#fff",
          }}
        >
          <Card.Img
            variant="top"
            src={`https://${imagesUrl}/media/${perfil.id_enlace}/images/${perfil.img_principal}`}
            className="rounded-circle mx-auto d-block"
            style={{ width: "150px" }}
          />
          <Card.Title className="mt-3">{perfil.nombre}</Card.Title>
          <Card.Text className="text-secondary mb-0">{perfil.grado}</Card.Text>
          <Card.Text className="mb-0">{perfil.puesto}</Card.Text>
          <hr />
          <Card.Text>
            <strong>Email:</strong><br/> {perfil.email}
          </Card.Text>
        </Card.Body>

        {/* Back Side */}
        <Card.Body
          className="flip-card-back bg-light"
          style={{
            position: "absolute",
            width: "100%",
            height: "100%",
            backfaceVisibility: "hidden",
            transform: "rotateY(180deg)",
          }}
        >
          {perfil.direccion && <Card.Text><strong>Dirección:</strong> {perfil.direccion}</Card.Text>}
          <Card.Text><strong>Extensión:</strong> {perfil.extension}</Card.Text>
          <Card.Text><strong>Especialidad:</strong> {perfil.especialidad}</Card.Text>
          {perfil.enlaces && <Card.Text><strong>Enlaces:</strong> {perfil.enlaces}</Card.Text>}
          <Button variant="primary" onClick={() => onShowModal(perfil)}>
            Más Información
          </Button>
        </Card.Body>
      </div>
    </div>
  );
};

export default FlipCard;
