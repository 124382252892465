import ExpComponent from "./ExperienciaTecnologica";
import MainSlider from "./MainSlider";
import WelcomeComponent from "./WelcomeComponent";
import HeaderWrapper from "./HeaderWrapper";
import Footer from "./Footer";
import NoticiasWrapper from "./NoticiasWrapper";
import ModalPopup from "./Popup/Modal";

function IndexWrapper() {
    return (
        <>
            <HeaderWrapper />
            <MainSlider />
            <WelcomeComponent />
            <NoticiasWrapper />
            <ExpComponent />
            <Footer />
            <ModalPopup />
        </>
    )
}

export default IndexWrapper;