import axios from "axios";
import React, { useEffect, useState } from "react";
import { Container, Row, Col, Tabs, Tab } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import Footer from "./Footer";
import GoUpButton from "./GoUpButton";
import HeaderWrapper from "./HeaderWrapper";
import LeftSideBarWrapper from "./LeftSideBarWrapper";
import DOMPurify from "dompurify";

const API_URL = process.env.REACT_APP_API_URL;

interface carreraProps {
    titulo: string;
    descripcion: string;
    imagen: string;
    tsu: string;
    perfil: string;
    perfil_egreso: string;
    mision: string;
    vision: string;
    cuerpo: string;
    ventajas: string;
    contacto: string;
    atributos: string;
    objetivos: string;
    id_enlace: string;
}

const CarreraPage: React.FC = () => {
    const { url } = useParams();
    const [sidebarMenuItems, setSidebarMenuItems] = useState<any[]>([]);
    const [infoCarrera, setInforCarrera] = useState<any>()
    const [info, setInfo] = useState<carreraProps>();
    const navigate = useNavigate()

    const sanitizeHtml = (html: string) => ({
        __html: DOMPurify.sanitize(html),
    });

    const getChilds = async (enlaceId: any) => {
        try {
            const response = await axios.post(`${API_URL}/enlaces/getChilds`,
                { id: enlaceId }
            )
            const items = response.data
            setSidebarMenuItems(items)
        } catch (error) {
            console.log('Error: ', error)
        }
    }

    const getCarrera = async (carrera: string) => {
        try {
            const response = await axios.post(`${API_URL}/enlaces/carrera`,
                { carrera: carrera }
            )
            const items = response.data;
            setInforCarrera(items);
        } catch (error) {
            console.log('Error: ', error)
        }
    }

    const getInfo = async () => {
        try {
            const response = await axios.get(`${API_URL}/enlaces/url/Carreras_Profesionales`)
            const items = response.data;
            setInfo(items);
            if (items.tipo === "titulo" || items.tipo === "acord") {
                await getChilds(items.id_enlace);
            }
        } catch (error) {
            //console.log('Error: ', error)
            navigate('/404');
        }
    }

    useEffect(() => {
        const fetchData = async () => {
            await getInfo();
            if (url) {
                await getCarrera(url);
                const violation = document.getElementById("inicio");
                window.scrollTo({
                    top: violation?.offsetTop,
                    behavior: "smooth"
                });
            }
        };
        fetchData();
    }, [url]);


    const RenderSection = ({ title, content }: { title: string; content?: string }) => {
        if (!content) return null;
        return (
            <>
                <h3>{title}</h3>
                <div style={{ textAlign: 'justify' }} dangerouslySetInnerHTML={sanitizeHtml(content)} />
            </>
        );
    };

    return (
        <>
            <HeaderWrapper />
            <Container fluid>
                <Row>
                    <Col className='menuBox' md={3} >
                        <LeftSideBarWrapper items={sidebarMenuItems} titulo={info?.titulo ?? ""} />
                    </Col>
                    {(url && infoCarrera) ?
                        <Col className='content-wrapper ps-5' md={6} tabIndex={0}>
                            <img src={`https://api.upchiapas.edu.mx/images/${infoCarrera?.id_enlace}/top.jpg`} alt="top" width="100%" id="inicio" />
                            <Tabs
                                defaultActiveKey="descripcion"
                                id="tabs-carrera"
                                className="mb-3"
                                fill>
                                <Tab eventKey="descripcion" title="DESCRIPCIÓN">
                                    <RenderSection title="Descripción" content={infoCarrera?.descripcion} />
                                    <RenderSection title="Misión" content={infoCarrera?.mision} />
                                    <RenderSection title="Visión" content={infoCarrera?.vision} />
                                    <RenderSection title="Perfil" content={infoCarrera?.perfil} />
                                    <RenderSection title="Perfil de Egreso" content={infoCarrera?.perfil_egreso} />
                                    <RenderSection title="Atributos" content={infoCarrera?.atributos} />
                                    <RenderSection title="Objetivos" content={infoCarrera?.objetivos} />
                                </Tab>
                                <Tab eventKey="tsu" title="TSU">
                                    {(infoCarrera.tsu) ?
                                        <>
                                            <div style={{ textAlign: 'justify' }} dangerouslySetInnerHTML={{ __html: infoCarrera.tsu }}
                                            />
                                        </> : <p>Tecnico superior en emprendimiento, formulación y evaluación de proyectos</p>
                                    }
                                </Tab>
                                <Tab eventKey="plan-estudios" title="PLAN DE ESTUDIOS">
                                    {(infoCarrera.cuerpo) ?
                                        <>
                                            <div style={{ textAlign: 'justify' }} dangerouslySetInnerHTML={{ __html: infoCarrera.cuerpo }}
                                            />
                                        </> : <></>
                                    }
                                </Tab>
                                <Tab eventKey="agendar-visita" title="AGENDAR VISITA">
                                    <iframe title="Visita" src="https://docs.google.com/forms/d/e/1FAIpQLSe680b4ql_2S_cBQ9m-948mUJKP_3jkMrH-uW7sM9V1kMpwiA/viewform?embedded=true" width="100%" height="500" frameBorder={0} marginHeight={0} marginWidth={0}>Cargando…</iframe>
                                </Tab>
                                <Tab eventKey="solicitar-ficha" title="SOLICITAR FICHA">
                                    <p className="pt-5">
                                        <Link to="https://siaup.upchiapas.edu.mx/registro">Da click aquí para comenzar el proceso de solicitud de ficha.</Link>
                                    </p>
                                </Tab>
                            </Tabs>
                        </Col> :
                        <Col className='content-wrapper ps-5' md={6} tabIndex={0}>
                            <img src="images/banner2x8B.jpg" alt="banner" width="100%" />
                        </Col>
                    }
                    <Col md={3} className='rightSideBar'></Col>
                    <GoUpButton />

                </Row >
            </Container >
            <Footer />
        </>
    )
}

export default CarreraPage;